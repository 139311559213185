<template>
  <div>
    <page-title :heading="$t('generic.lang_archive')" :icon=icon :subheading="$t('generic.lang_archive')"
    ></page-title>
    <div class="app-main__inner">
      <kantinopel-archive-component/>
    </div>
  </div>
</template>
<script>
import PageTitle from "@/Layout/Components/PageTitle";
import KantinopelArchiveComponent from "@/components/kantinopel/KantinopelArchiveComponent";

export default {
  name: "KantinopelArchive",
  components: {
    PageTitle,
    KantinopelArchiveComponent
  },
  data: () => ({
    icon: 'pe-7s-shuffle icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>