<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0 mb-0" cols="12">
        <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card mb-3">
          <div class="no-gutters row">
            <v-progress-linear v-if="this.loadData" class="pa-0 ma-0" indeterminate/>
            <div class="col-md-12 col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'calendar-check']" class="text-success " size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{$t('generic.lang_confirmed')}}</div>
                          <div class="widget-subheading">{{$t('generic.lang_onlyForToday')}}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-success">
                            <countTo :duration='3000' :endVal='parseInt(this.statistics.confirmed)'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'globe']" class="text-info " size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>

                        <div class="widget-content-left">
                          <div class="widget-heading">{{$t('generic.lang_online')}}</div>
                          <div class="widget-subheading">{{$t('generic.lang_onlyForToday')}}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-info">
                            <countTo :duration='3000' :endVal='parseInt(this.statistics.online)'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'user-times']" class="text-danger " size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>
                        <div class="widget-content-left">
                          <div class="widget-heading">{{$t('generic.lang_canceled')}}</div>
                          <div class="widget-subheading">{{$t('generic.lang_onlyForToday')}}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-danger">
                            <countTo :duration='3000' :endVal='parseInt(this.statistics.canceled)'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'phone']" class="text-warning " size="2x"
                                               style="font-size: 40px; color: purple !important"/>
                          </div>
                        </div>

                        <div class="widget-content-left">
                          <div class="widget-heading">{{$t('generic.lang_viaPhone')}}</div>
                          <div class="widget-subheading">{{$t('generic.lang_onlyForToday')}}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers" style="color: purple !important">
                            <countTo :duration='3000' :endVal='parseInt(this.statistics.phone)'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="col-md-12 col-lg-4">
              <ul class="list-group list-group-flush">
                <li class="bg-transparent list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-numbers  pr-3">
                            <font-awesome-icon :icon="['fal', 'users']" class="text-secondary" size="2x"
                                               style="font-size: 40px"/>
                          </div>
                        </div>

                        <div class="widget-content-left">
                          <div class="widget-heading">{{ this.$t('customers.lang_customers') }}</div>
                          <div class="widget-subheading">{{ $t('generic.lang_lastYearExpenses') }}</div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-secondary">
                            <countTo :duration='3000' :endVal='parseInt(this.statistics.customers)'
                                     :startVal='0'></countTo>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="bg-transparent list-group-item pa-0">
                  <v-progress-linear v-if="this.loading" class="pa-0 ma-0" indeterminate/>
                  <div class="widget-content pt-3">

                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </v-col>


      <v-col class="pt-0 mt-0" cols="12">
        <div class="content mt-0 pa-0">
          <!--reservation types -->
          <div v-if="currentTab === 'tab1'">

            <v-card class="main-card mb-3 card pa-0 ma-0 border-top" outlined>
              <kantinopel-archive-list-component/>
            </v-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar,
  faCalendarCheck,
  faCalendarTimes,
  faGlobe,
  faList,
  faPhone,
  faPowerOff,
  faStream,
  faUserCheck,
  faUsers,
  faUserTimes
} from '@fortawesome/pro-light-svg-icons';
import countTo from 'vue-count-to';
import KantinopelArchiveListComponent from "@/components/kantinopel/KantinopelArchiveListComponent";

library.add(faCalendar, faList, faUserCheck, faUserTimes, faUsers, faGlobe, faPhone, faPowerOff, faCalendarTimes, faCalendarCheck, faStream);
export default {
  name: "KantinopelArchiveComponent",
  components: {
    KantinopelArchiveListComponent,
    countTo,
    'font-awesome-icon': FontAwesomeIcon,
  },
  data: () => {
    return {
      tab: 0,
      tabs: "",
      currentTab: "tab1",
      loadData: false,
      loading: false,
      statistics: {
        online: 0,
        confirmed: 0,
        canceled: 0,
        phone: 0,
        customers: 0
      }
    }
  },
  methods: {
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    changeTab(tab) {
      this.tab = tab;
    },
  }
}
</script>

<style scoped>

</style>
