<template>
  <v-container class="px-0 mx-0 pa-0" fluid>
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : 'white']" class="card-title  card-header">
        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('generic.lang_archive') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn">
          <div class="btn-actions-pane-right actions-icon-btn pt-2">
            <v-btn class="elevation-0 pl-2 pr-2" color="green" dark  :disabled ="!this.details" @click="BackToData()">
              <v-icon class="ml-0 pl-0">
                mdi-arrow-left
              </v-icon>
              {{ this.$t('generic.lang_prev') }}
            </v-btn>
          </div>
        </div>
      </div>
      <v-row class="pa-0 ma-0">
        <v-col class="pt-0 mt-0" cols="12">
          <v-row class="pt-0 mt-0">
            <v-col class="pa-0" cols="12">

              <v-data-table
                  :items="data" :headers="headers" :loading="loading"
              >
                <template v-if="!this.details" v-slot:item.menu="{item}">
                  <v-btn  fab x-small  depressed color="primary" @click="showDetails(item)">
                    <v-icon>
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>

                <!--<template v-slot:item.crudDelete="{item}">
                  <v-btn fab x-small depressed color="error" @click="item">
                    <v-icon>
                      mdi-trash-can
                    </v-icon>
                  </v-btn>
                </template>-->
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboard"
                          :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"/>
    </div>
  </v-container>
</template>

<script>
import mixin from "@/mixins/KeyboardMixIns";
import Datatable from "@/components/datatable/Datatable";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "KantinopelArchiveListComponent",
  components: {Datatable},
  mixins: [mixin],
  data: () => {
    return {
      ENDPOINTS,
      loading:false,
      picker: false,
      details:false,
      type: -1,
      source: -1,
      status: -1,
      search: "",
      requested_date: "",
      data:[],
      orders:[],
      archive:[],

    }
  },
  computed: {
    params: function () {
      return {
        order_tuype: this.type,
        order_status: this.status,
        order_date: this.requested_date
      }
    },
    headers: function () {
      return [
        {
          text: this.$t('generic.lang_name'),
          value: 'name'
        },
        {
          text: this.details? this.$t('generic.lang_cashier_price') : '',
          value: 'pricePerItemTotal'
        },
        {
          text: this.$t('generic.lang_menu'),
          value: 'menu',
          sortable:false,
          width:100,
          align:'center'
        },
        {
          text: '',
          value: 'crudDelete',
          sortable:false,
          width:100,
          align:'center'
        },

      ]
    },
    excelColumns: function () {
      return [
        {
          label: "ID",
          field: 'id'
        },
        {
          label: this.$t('customers.name'),
          field: 'name'
        },
        {
          label: this.$t('generic.lang_class'),
          field: 'class'
        },
        {
          label: this.$t('generic.lang_display'),
          field: 'show'
        },
        {
          label: this.$t('generic.lang_status'),
          field: 'status'
        }
      ]
    }
  },

  mounted() {
    this.getOrders();
  },
  methods: {
    displayEntry(entry) {

    },
    entryEdit(entry) {

    },
    getOrders(){
      this.loading=true

      this.axios.post("get/canteen/orderArchive/").then((res) => {
        if (res.data.status === 'SUCCESS') {
          this.loading = false;
          this.data=res.data.items
          this.archive=res.data.items
          //console.log(this.data);


        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.data=this.archive
        this.loading = false;
      })

    },
    showDetails(item){
      if(this.details==false){
        this.details=true;
        this.data=item.orders
      }
    },
    BackToData(){
      this.details=false;
      this.data=this.archive
    }
  }
}
</script>

<style scoped>

</style>
